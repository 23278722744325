import React from "react";
import "./ClickHereGetInfo.css";

const ClickHereGetInfo = () => {
  return (
    <div>
      <h1>This is click here get info</h1>
    </div>
  );
};

export default ClickHereGetInfo;
